import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { DetailHeader, DetailStart, DetailCompatibility,DetailVideos,DetailFaq } from '@/components/Support/index'
import {
    windowIcon,
    macIcon
} from '@/images/index'
import { play8, playImg8} from "@/images/support"
import { useIntl } from "react-intl"
import { useGTMInit} from '@/hooks/useGTM.js'

const Index = () => {
    const intl = useIntl()
    useEffect(()=>{
        useGTMInit('support','air2')
      },[])

    const list1 = [
        {
            device: {
                img: windowIcon,
                name: 'Windows'
            },
            support: [
                {
                    // type:"dp",
                    comingsoon: true,
                    desc: intl.formatMessage({ id: "coming_sone_text", defaultMessage: "Coming soon" })
                    // desc:"<span>Coming Soon</span>"
                }
            ]
            ,
            // support: [
            //     {
            //         type: "mac",
            //         desc: intl.formatMessage({ id: "air_use_windows", defaultMessage: "<span>NVIDIA GPU</span>laptops" })
            //     },

            // ],
            // tips:[
            //     intl.formatMessage({ id: "air_use_windows_tip3", defaultMessage: '* Windows 10 or 11 64-bit' }),
            //     intl.formatMessage({ id: "air_use_windows_tip1", defaultMessage: '* Nvidia GTX 1650 (Recommended: RTX3060 and above)' }), intl.formatMessage({ id: "air_use_windows_tip2", defaultMessage: '* USB-C DisplayPort (DP 1.2)' }),
            //     intl.formatMessage({ id: "air_use_windows_tip4", defaultMessage: "* Please read the <a href='https://www.reddit.com/r/Xreal/wiki/index/nebula/nebulaforwindows/' target='_blank'>FAQ</a> before use"})
            // ]
        },
        {
            device: {
                img: macIcon,
                name: 'MacBook'
            },
            support: [
                {
                    type: "mac",
                    desc: intl.formatMessage({ id: "air_use_mac", defaultMessage: "<span>M1/M2</span>MacBook" })
                },

            ],
            tips: [
                intl.formatMessage({ id: "air_use_mac_tip1", defaultMessage: '* macOS 12.0 and above' }), intl.formatMessage({ id: "air_use_mac_tip2", defaultMessage: '* 2019 and later Macbook models' })
            ]
        }
    ]

    const videoList = [
        {
            img: playImg8,
            link: play8,
            title: intl.formatMessage({ id: "support_video_8", defaultMessage:'XREAL Air 2 and Air 2 Pro User Guide'}),
            time: "05:01"
        }
    ]
    return (
        <Layout menuBlack={true}>
            <SEO page="Support" />
            <DetailHeader pro='air2' />
            <DetailStart updateLink='/support/update/#air2' manualsLink='/support/XREAL_Air2_Quick_Start_Guide_pdf' manualsLink2='/support/XREAL_Air2Pro_Quick_Start_Guide_pdf' type='air2' videoList={videoList}  />
            {/* <DetailVideos list={videoList} /> */}
            <DetailCompatibility tabArr={['Air Casting', 'Spatial Display', 'Virtual Desktop', 'AR Space']} list1={list1} />
            <DetailFaq type='air2' />
        </Layout>
    )
}

export default Index